import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useParams,
  Link,
} from "react-router-dom";
import "./App.css";
import ReactLoading from "react-loading";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
function ResultPage() {
  const { id } = useParams();
  const [data, setData] = useState(null);
  const [groupTitles, setGroupTitle] = useState([]);

  useEffect(() => {
    fetch(`https://heron-loving-broadly.ngrok-free.app/api/wasl-info/${id}`, {
      headers: {
        "ngrok-skip-browser-warning": "true",
      },
    })
      .then((response) => response.json())
      .then((data) => setData(data))
      .catch((error) => console.error("Error fetching data:", error));
  }, [id]);

  useEffect(() => {
    const groupData = () => {
      const groups = [];
  
      data?.Items.forEach((item) => {
        // Check if the item type already exists in the groups array
        const existingGroup = groups.find(group => group.name === item?.Type_of_Item);
  
        if (!existingGroup) {
          // Add the item to groups if it doesn't already exist
          const newGroup = {
            name: item?.Type_of_Item,
            Active_Status: item?.Active_Status,
          };
          groups.push(newGroup);
        }
      });
  
      setGroupTitle(groups);
    };
  
    groupData();
  }, [data]);
  

  if (!data) {
    return (
      <div className=" w-full h-[100vh] flex flex-col  justify-center items-center gap-4 ">
        <Link
          to={`/`}
          className="w-full fixed top-0  p-2 rounded-b-[2rem] bg-primary flex flex-col justify-center items-center "
        >
          <div className=" w-full center  gap-1 p-2 ">
            <div className=" flex-1    text-xs text-white text-center">
              Dosty Private Hospital
            </div>
            <img src="./dmc.png" alt="./dmc.png" className=" w-20"></img>

            <div className=" flex-1  text-xs text-white text-center  ">
              نەخۆشخانەی دۆستی تایبەت
            </div>
          </div>
        </Link>
        <ReactLoading
          type={"spinningBubbles"}
          color={"#2A90FC"}
          height={100}
          width={100}
        />
        <div className=" w-full  center text-primary text-xl text-center ">
          {`
        تکایە چاوەڕێ بکە تاوەکو ئەنجامی شیکارییەکەت ئامادە دەبێت
        `}
        </div>
        <div className=" w-full  center text-black text-xs text-center">
          {`
        ئەم سیستەمە لەلایەن دابان تەکنەلۆجی پەرەی پێدەدرێت
        `}
        </div>
        <Link to={`https://www.facebook.com/DabanTechnology`} target="_blank">
          <img src="./daban1.png" className=" w-64 "></img>
        </Link>
      </div>
    );
  }
  return (
    <div className=" w-full h-[100vh] flex flex-col gap-2 justify-normal items-center">
      <Link
        to={`/`}
        className="w-full  p-2 rounded-b-[2rem] bg-primary flex flex-col justify-center items-center "
      >
        <div className=" w-full center  gap-1 p-2 ">
          <div className=" flex-1    text-xs text-white text-center">
            Dosty Private Hospital
          </div>
          <img src="./dmc.png" alt="./dmc.png" className=" w-20"></img>

          <div className=" flex-1  text-xs text-white text-center  ">
            نەخۆشخانەی دۆستی تایبەت
          </div>
        </div>
      </Link>
      <div className="w-[95%]  p-2   bg-secondary flex flex-col justify-center items-center ">
        ئەنجامی تاقیگە
      </div>
      <div className=" w-[95%] ">
        <div className=" w-full   flex flex-row">
          <div className="w-full    flex flex-row flex-[3]">
            <div className="w-full   flex-[1] text-xs">Name:</div>
            <div className="w-full   flex-[3] text-xs">
              {data?.Customer?.CustName}
            </div>
          </div>
          <div className="w-full   flex-[2] flex">
            <div className="w-full   flex-[1] text-xs">Age:</div>
            <div className="w-full   flex-[1] text-xs">
              {data?.Customer?.Age}
            </div>
          </div>
          <div className="w-full  flex-[2] flex">
            <div className="w-full   flex-[1] text-xs">Gender:</div>
            <div className="w-full   flex-[1] text-xs">
              {data?.Customer?.Gender}
            </div>
          </div>
        </div>
        <div className=" w-full   flex flex-row">
          <div className="w-full gap-2    flex flex-row flex-[3]">
            <div className="w-full   flex-[1] text-xs">Mobile:</div>
            <div className="w-full   flex-[3] text-xs">
              {data?.Customer?.Mobile_1}
            </div>
          </div>
          <div className="w-full   flex-[4] flex">
            <div className="w-full   flex-[1] text-xs">Date:</div>
            <div className="w-full   flex-[3] text-xs">
              {data?.Date.split("T")[0]}
            </div>
          </div>
          {/* <div className="w-full  flex-[2] flex"></div> */}
        </div>
        <div className=" w-full   flex flex-row">
          <div className="w-full gap-2    flex flex-row flex-[3]">
            <div className="w-full   flex-[1] text-xs">Doctor:</div>
            <div className="w-full   flex-[3] text-xs"></div>
          </div>
          <div className="w-full   flex-[2] flex">
            <div className="w-full   flex-[1] text-xs">Code:</div>
            <div className="w-full   flex-[1] text-xs">
              {data?.Customer?.CustID}
            </div>
          </div>
          <div className="w-full  flex-[2] flex"></div>
        </div>
        <div className=" w-full   flex flex-row">
          <div className="w-full gap-2    flex flex-row flex-[3]">
            <div className="w-full   flex-[1] text-xs">Invoice.No:</div>
            <div className="w-full   flex-[3] text-xs"> {data?.Num_Wasl}</div>
          </div>
          <div className="w-full   flex-[2] flex"></div>
          <div className="w-full  flex-[2] flex"></div>
        </div>
      </div>
      {groupTitles &&
        groupTitles.map((title) => (
          <>
            <div className="w-[95%]  p-2 text-red-700    flex flex-col justify-center items-center ">
              {title && title.name}
            </div>
            {title.Active_Status ? (
              <div className="w-[95%]  py-2 bg-primary text-white  flex-row flex ">
                <div className="w-full p-2  flex-[2] text-xs flex justify-start items-center">
                  Name
                </div>
                <div className="w-full p-2  flex-1 text-xs flex justify-start items-center">
                  Result
                </div>
                <div className="w-full p-2  flex-1 text-xs flex justify-start items-center">
                  Unit
                </div>
                <div className="w-full p-2  flex-[1] text-xs flex justify-start items-center">
                  Status
                </div>
                <div className="w-full p-2  flex-[2] text-xs flex justify-start items-center">
                  Normal Range
                </div>
              </div>
            ) : (
              <div className="w-[95%]  py-2 bg-primary text-white  flex-row flex ">
                <div className="w-full p-2  flex-[1] text-xs flex justify-start items-center">
                  Name
                </div>
                <div className="w-full p-2  flex-1 text-xs flex justify-start items-center">
                  Result
                </div>
              </div>
            )}

            {data &&
              data?.Items.map((item) => {
                if (item?.Type_of_Item == title.name) {
                  if (item?.Active_Status == 1) {
                    return (
                      <div
                        key={item?.Barcode_Code}
                        className="w-[95%]  py-2   flex-row flex "
                      >
                        <div className="w-full p-2  flex-[2] text-xs flex justify-start items-center">
                          {item?.Name}
                        </div>
                        <div className="w-full p-2  flex-1 text-xs flex justify-start items-center">
                          {item?.Wasl_Note_Result}
                        </div>
                        <div className="w-full p-2  flex-1 text-xs flex justify-start items-center">
                          {item?.ItemUnit}
                        </div>
                        <div className="w-full p-2  flex-[1] text-xs flex justify-start items-center">
                          {item?.Status}
                        </div>
                        <div className="w-full p-2  flex-[2] text-[0.6rem] flex justify-start items-center">
                          {item?.Normal_Range}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        key={item?.Barcode_Code}
                        className="w-[95%]  py-2   flex-row flex "
                      >
                        <div className="w-full p-2  flex-[1] text-xs flex justify-start items-center">
                          {item?.Name}
                        </div>
                        <div className="w-full p-2  flex-1 text-xs flex justify-start items-center">
                          {item?.Wasl_Note_Result}
                        </div>
                      </div>
                    );
                  }
                }
              })}
          </>
        ))}

      <hr className=" border-[0.01rem] w-[95%]" />
      <div className="w-[95%]  py-2   flex-row flex ">
        <div className="w-full p-2  flex-1 text-xs ">Note: </div>
        <div className=" p-2  flex-[9] text-xs">{}</div>
      </div>
      <div className="w-full p-2   bg-primary flex flex-col justify-center items-center">
        <div className=" w-full center  gap-1 p-2 ">
          <div className=" flex-1    text-[0.5rem] text-white text-center">
            Developed By Daban Technology
          </div>
          <img src="./daban.png" alt="./daban.png" className=" w-24"></img>

          <div className=" flex-1 text-[0.5rem]   text-white text-center  ">
            پرۆگرامکراوە لەلایەن دابان تەکنەلۆجی
          </div>
        </div>
      </div>
    </div>
  );
}
function HomePgae() {
  return (
    <div className=" w-full h-[100vh] flex flex-col gap-2 justify-normal items-center">
      <Link
        to={`/`}
        className="w-full  p-2 rounded-b-[2rem] bg-primary flex flex-col justify-center items-center "
      >
        <div className=" w-full center  gap-1 p-2 ">
          <div className=" flex-1    text-xs text-white text-center">
            Dosty Private Hospital
          </div>
          <img src="./dmc.png" alt="./dmc.png" className=" w-20"></img>

          <div className=" flex-1  text-xs text-white text-center  ">
            نەخۆشخانەی دۆستی تایبەت
          </div>
        </div>
      </Link>
      <img src="./qr.png" className=" w-full md:w-96"></img>
      <div className=" w-full  center text-primary text-xl text-center ">
        {`
      ئەنجامی شیکارییەکەت تەنیا بەیەک سکان ببینەوە 
      `}
      </div>
      <div className=" w-full  center text-black text-xs text-center">
        {`
      ئەم سیستەمە لەلایەن دابان تەکنەلۆجی پەرەی پێدەدرێت
      `}
      </div>
      <Link to={`https://www.facebook.com/DabanTechnology`} target="_blank">
        <img src="./daban1.png" className=" w-64 "></img>
      </Link>

      {/* <div className="w-full p-2 fixed  bottom-0   bg-primary flex flex-col justify-center items-center">
        <div className=" w-full center  gap-1 p-2 ">
          <div className=" flex-1    text-[0.5rem] text-white text-center">
            Developed By Daban Technology
          </div>
          <img src="./daban.png" alt="./daban.png" className=" w-24"></img>

          <div className=" flex-1 text-[0.5rem]   text-white text-center  ">
            پرۆگرامکراوە لەلایەن دابان تەکنەلۆجی
          </div>
        </div>
      </div> */}
    </div>
  );
}
function ViewData() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [fahs, setFahs] = useState([]);

  useEffect(() => {
    const getFahs = async () => {
      const res = await fetch(
        `https://heron-loving-broadly.ngrok-free.app/api/wasl-info/`,
        {
          headers: {
            "ngrok-skip-browser-warning": "true",
          },
        }
      )
        .then((response) => response.json())
        .then((data) => setFahs(data))
        .catch((error) => console.error("Error fetching data:", error));
    };
    getFahs();
  }, []);

  // Filtered Fahs data based on the selected start and end dates
  const filteredFahs = fahs.filter((f) => {
    const fahsDate = new Date(f.Date);
    // Normalize the dates by setting the time to midnight
    const normalizedFahsDate = new Date(
      fahsDate.getFullYear(),
      fahsDate.getMonth(),
      fahsDate.getDate()
    );
    const normalizedStartDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate()
    );
    const normalizedEndDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate()
    );
    return (
      normalizedFahsDate >= normalizedStartDate &&
      normalizedFahsDate <= normalizedEndDate
    );
  });

  return (
    <div className=" w-full h-[100vh] flex flex-col gap-2 justify-normal items-center">
      <Link
        to={`/`}
        className="w-full  p-2 rounded-b-[2rem] bg-primary flex flex-col justify-center items-center "
      >
        <div className=" w-full center  gap-1 p-2 ">
          <div className=" flex-1    text-xs text-white text-center">
            Dosty Private Hospital
          </div>
          <img src="./dmc.png" alt="./dmc.png" className=" w-20"></img>

          <div className=" flex-1  text-xs text-white text-center  ">
            نەخۆشخانەی دۆستی تایبەت
          </div>
        </div>
      </Link>
      <div className="w-full flex justify-center   px-4 py-2  gap-2">
        <div className=" w-24 center">from</div>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          className=" border-2 text-primary w-24 flex-[2] text-center rounded-md border-primary"
        />
        <div className="w-24 center">to</div>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          className=" border-2 text-primary w-24 flex-[2] text-center rounded-md border-primary"
        />
      </div>
      <div className="w-full center p-2 text-xs  gap-2">
        <div>{filteredFahs.length}</div>
        <div>:</div>
        <div>کۆی گشتی دۆزراوە</div>
      </div>
      <div className="w-[95%]  py-2 bg-primary text-white  flex-row flex ">
        <div className="w-full p-2  flex-[2] text-xs flex justify-start items-center">
          Invoice number
        </div>
        <div className="w-full p-2  flex-1 text-xs flex justify-start items-center">
          Code
        </div>
        <div className="w-full p-2  flex-[2] text-xs flex justify-start items-center">
          Name
        </div>
        <div className="w-full p-2  flex-[1] text-xs flex justify-start items-center">
          gender
        </div>
        <div className="w-full p-2  flex-[1] text-xs flex justify-start items-center">
          Age
        </div>
      </div>
      {filteredFahs &&
        filteredFahs.map((f, index) => (
          <div
            key={index}
            className="w-[95%]  py-2  text-black  flex-row flex "
          >
            <Link
              to={`/${f?.Num_Wasl}`}
              className="w-full p-2 text-blue-400 underline  flex-[2] text-xs flex justify-start items-center"
            >
              {f?.Num_Wasl}
            </Link>
            <div className="w-full p-2  flex-1 text-xs flex justify-start items-center">
              {f?.CustID}
            </div>
            <div className="w-full p-2  flex-[2] text-xs flex justify-start items-center">
              {f?.CustName}
            </div>
            <div className="w-full p-2  flex-[1] text-xs flex justify-start items-center">
              {f?.Gender}
            </div>
            <div className="w-full p-2  flex-[1] text-xs flex justify-start items-center">
              {f?.Age}
            </div>
          </div>
        ))}

      {/* <div className="br w-full p-2">{console.log(filteredFahs)}</div> */}
      <div className="w-full p-2 fixed  bottom-0   bg-primary flex flex-col justify-center items-center">
        <div className=" w-full center  gap-1 p-2 ">
          <div className=" flex-1    text-[0.5rem] text-white text-center">
            Developed By Daban Technology
          </div>
          <img src="./daban.png" alt="./daban.png" className=" w-24"></img>

          <div className=" flex-1 text-[0.5rem]   text-white text-center  ">
            پرۆگرامکراوە لەلایەن دابان تەکنەلۆجی
          </div>
        </div>
      </div>
    </div>
  );
}
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:id" element={<ResultPage />} />
        <Route path="/" element={<ViewData />} />
        <Route path="*" element={<ViewData />} />
      </Routes>
    </Router>
  );
}

export default App;
